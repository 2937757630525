<template>
  <div>
    <div v-if="isLoading"><Loader/></div>
    <div v-else>
    <div class="profile-view" id="bookshop-cover" :style="{backgroundImage: 'url(' + plainUrl + item.img_url + ')'}">
      <section class="profile-view-content">
      <div class="ml-2 mr-3">
        <div class="profile-view-motto bookstore-name">{{item.name}}</div>
        <div class="medium-container">
          <div class="adress-container">
            <div class="profile-view-name">{{item.street}}</div>
            <div class="profile-view-location">{{item.postcode}} {{item.city}}</div>
            <div class="profile-view-location" @click="callPhone" >tel. {{item.phone}}</div>
          </div>
        </div>
        <div class="profile-view-name mt-2 mb-2">Godziny otwarcia</div>
        <div class="hours-container">
          <div class="profile-view-location mb-1"><span class="mr-2">Poniedziałek</span> {{item.open_m}}</div>
          <div class="profile-view-location mb-1"><span class="mr-2">Wtorek</span> {{item.open_tu}}</div>
          <div class="profile-view-location mb-1"><span class="mr-2">Środa</span> {{item.open_w}}</div>
          <div class="profile-view-location mb-1"><span class="mr-2">Czwartek</span> {{item.open_th}}</div>
          <div class="profile-view-location mb-1"><span class="mr-2">Piątek</span> {{item.open_f}}</div>
          <div class="profile-view-location mb-1"><span class="mr-2">Sobota</span> {{item.open_sat}}</div>
          <div class="profile-view-location"><span class="mr-2">Niedziela</span> {{item.open_sun}}</div>
        </div>
      </div>
      </section>
    </div>
    <div class="container map-container mt-3 mb-3 text-center" v-if="item.lang && item.lat">
      <div class="location-text"><i class="fas fa-map-marker-alt"></i> Znajdź księgarnie</div>
      <button class="btn-black-border w-100 mt-3 mb-2" @click="showMap = !showMap">
        <span v-if="showMap === false">Sprawdź</span>
        <span v-if="showMap === true">Zamknij</span>
      </button>
    </div>
    <div>
      <transition name="fade" v-if="item.lang && item.lat">
        <div id="maps" v-if="showMap">
        <GmapMap
          :center="{lat: lat, lng: lng}"
          :zoom="14"
          map-type-id="terrain"
          style="width: 100vw; height: 450px"
        >
        <GmapMarker
            :position="{lat: lat, lng: lng}"
            :clickable="true"
            :draggable="false"
          />
        </GmapMap>
        </div>
      </transition>
    </div>
    <div class="books-container bg-gray">
        <div class="pt-4 pb-4" v-if="item.shelfs.length">
        <OtherUserTabs :activeTab="activeTab" compId="bookstore" :tabs="tabs" :library="bookstoreShelves">
          <div v-for="(e, key) in tabs" :key="key">
          <template :slot="'tab-head-' + e.id" >
          </template>
          <template :slot="'tab-panel-' + e.id">
          </template>
        </div>
      </OtherUserTabs>
        </div>
    </div>
    </div>
  </div>
</template>
<script>
import Loader from '../components/Loader'
// import OtherUserTabs from '../components/OtherUserTabs'
export default {
  name: 'BookstoreDetails',
  props: {
    id: String,
    props: [Array, Object]
  },
  components: {
    Loader
    // OtherUserTabs
  },
  data () {
    return {
      item: [],
      isLoading: true,
      bookstoreShelves: [],
      tabs: [],
      activeTab: '',
      showMap: false,
      lat: 10,
      lng: 10
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    callPhone () {
      const phonenbr = this.item.phone.replace(/\s/g, '')
      window.open('tel:' + phonenbr)
    },
    fetchData () {
      this.isLoading = true
      this.$https('/bookshop/' + this.id + '/profile', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(res => {
          this.item = res.data.response
          this.lat = parseFloat(this.item.lat, 10)
          this.lng = parseFloat(this.item.lang, 10)
          this.bookstoreShelves = this.item.shelfs_wb
          this.isLoading = false
          if (this.item.shelfs.length) {
            this.bookstoreShelves.forEach(e => {
              this.tabs.push({
                id: e.id,
                name: e.label
              })
            })
          }
          this.activeTab = this.bookstoreShelves[0].id
        })
        .catch(error => {
          console.log('', error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
  #bookshop-cover {
    &::before {
      content: '';
      height: 100%;
      width: 100%;
      background: rgb(0, 0, 0);
    }
  }
  .medium-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .location-text {
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    color: #000;
    margin-bottom: 0;
  }
  .books-container {
    margin-bottom: 60px;
  }
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.bookstore-name {
  word-break: break-all;
}
</style>
